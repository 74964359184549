import styled, { css } from 'styled-components';
import { Typography } from 'Atoms';

export const Description = styled(Typography)`
  color: ${({ theme }) => theme.colors.black[500]};

  & span {
    font-weight: 700;
  }
`;

export const Background = styled.div`
  background-color: ${({ theme }) => theme.colors.yellow[700]};
  display: flex;
  border-radius: 0.3rem;
  padding: 0.5rem;

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      position: absolute;
      top: -2.8125rem;
      right: 0;
      left: 0;
      margin: 0px auto;
      padding: 0.8rem 0.5rem 0.5rem;
      justify-content: center;
      width: fit-content;

      p {
        font-size: 0.75rem;
      }
    }
  `}
`;
