import * as React from 'react';
// import { useSelector } from 'react-redux';
import { useSession } from 'next-auth/react';
// import { RootState } from 'Client/redux-store';
import { useRouter } from 'next/router';
import { OnboardingLoginTemplate as PageTemplate } from 'Templates';
// import { CommunityMemberLogin } from './components/CommunityMemberLogin';
// import { MainLogin } from './components/MainLogin/MainLogin';
// import { ProjectTeamLogin } from './components/ProjectTeamLogin';
// import { ResetPassword } from './components/ResetPassword';
import { Wrapper, Content, FormWrapper } from './LoginPage.styles';
import { OnboardingLoginParams } from './types';

export const LoginPage: React.FC<OnboardingLoginParams> = ({
  slug,
}: OnboardingLoginParams) => {
  // const isXL = typeof window !== 'undefined' && window.innerWidth > 1950;
  // const isMobile = typeof window !== 'undefined' && window.innerWidth < 420;
  const [image, setImage] = React.useState<{ width?: string; src?: string }>({
    src: '',
    width: '85%',
  });
  const [backButton, setBackButton] = React.useState<boolean>(false);

  slug;
  setBackButton;
  setImage;
  // const [form, setForm] = React.useState(undefined);
  // const { userLoginInfo } = useSelector(
  //   (state: RootState) => state.userLoginInfo
  // );

  // const { userName: name, email } = userLoginInfo;

  // React.useEffect(() => {
  //   const renderForm = (slug: Array<string>) => {
  //     const memberRole = slug && slug[0] ? slug[0] : null;
  //     switch (memberRole) {
  //       case 'project-team':
  //         setForm(<ProjectTeamLogin prefilledEmail={'test'} username={name} />);
  //         if (!isMobile) setBackButton(true);
  //         setImage({
  //           src: '/static/illustrations/customers_login.png',
  //           width: isXL ? '83%' : '60%',
  //         });
  //         break;
  //       case 'community-member':
  //         setForm(<CommunityMemberLogin email={email} />);
  //         if (!isMobile) setBackButton(true);
  //         setImage({
  //           src: '/static/illustrations/code_verification_image.png',
  //           width: isXL ? '83%' : '75%',
  //         });
  //         break;
  //       case 'reset-password':
  //         setImage({ src: null });
  //         if (!isMobile) setBackButton(true);
  //         setForm(<ResetPassword />);
  //         break;
  //       default:
  //         setForm(<MainLogin />);
  //         setImage({
  //           src: '/static/illustrations/main_login.png',
  //           width: isXL ? '83%' : '60%',
  //         });
  //         break;
  //     }
  //   };

  //   renderForm(slug);
  // }, [email, name, slug]);
  const { data: session } = useSession();
  const router = useRouter();

  React.useEffect(() => {
    console.log('LoginPage.tsx - session: ', session);
  }, [session]);

  const [showIframe, setShowIframe] = React.useState(false);
  const [systemZoom, setSystemZoom] = React.useState(0);

  React.useEffect(() => {
    const width =
      window.innerWidth ?? document.documentElement.clientWidth ?? screen.width;

    setSystemZoom(width / window.screen.availWidth);

    const handleMessage = (event) => {
      // Ensure the message comes from the expected origin
      if (event.origin !== window.location.origin) return;

      if (event.data === 'closeIframe') {
        setShowIframe(false);

        return router.push(window.location.origin);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [router]);

  return (
    <PageTemplate image={image} backButton={backButton}>
      <Wrapper data-testid="LoginPage-Wrapper">
        <Content>
          <FormWrapper>
            <div>
              <button type="button" onClick={() => setShowIframe(true)}>
                Sign In with Descope
              </button>
            </div>

            <div>
              {showIframe && (
                <iframe
                  src="/descope-signin"
                  id="embedded-sign-in"
                  height={String(500 / systemZoom)}
                  width={String(500 / systemZoom)}
                />
              )}
            </div>
          </FormWrapper>
        </Content>
      </Wrapper>
    </PageTemplate>
  );
};
