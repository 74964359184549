import * as React from 'react';
import styled, { css } from 'styled-components';
import { Typography } from 'Atoms';
import { LayoutScroll } from 'Client/components/organisms';

export const Wrapper = styled.div`
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: shadow 10% #000;

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      padding: 0 1rem;
    }
  `}
`;

export const CarouselWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    width: 0.4375rem;
    height: 0.625rem;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    background: ${({ theme }) => theme.colorMappings.primaryButtonBackground};
  }

  &::-webkit-scrollbar-track {
    border-radius: 0.5rem;
    background: ${({ theme }) => theme.colors.grey[400]};
  }

  & img {
    width: 5rem;
    height: 5rem;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      overflow: hidden;
    }

    ${theme.breakpoints.down('xs')} {
      & img {
        width: unset;
        height: 14rem;
        margin-top: 4rem;
      }
    }
  `}
`;

export const ImgWrapper = styled.button<{ active?: boolean }>`
  cursor: pointer;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;

  ${({ active }) =>
    active &&
    css`
      & img {
        display: block;
        opacity: 0.75;
      }

      border-bottom: 0.25rem solid
        ${({ theme }) => theme.colorMappings.primaryButtonBackground};
    `};

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      border-bottom: none;

      & img {
        margin-top: 0;
      }
    }
  `}
`;

export const CategoryWrapper = styled.div`
  display: flex;
  align-items: center;

  & p {
    margin-left: 1rem;
    font-size: 1.3125rem;
  }
  margin-bottom: 1rem;
`;

export const InfoWrapper = styled.div`
  display: flex;
  justify-content: center;

  & div:first-child {
    align-items: flex-start;
    text-align: start;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      flex-direction: column;
      margin-bottom: 1.25rem;
    }
  `}
`;

export const LabelText = styled(Typography)`
  color: ${({ theme }) => theme.colors.black[500]};
  opacity: 0.35;
  font-size: 0.6875rem;

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      margin-top: 0.25rem;
      text-align: start;
      min-width: 5.4375rem;
    }
  `}
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  width: 20%;

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      flex-direction: row;
      align-items: flex-start;
      width: 100%;
    }
  `}
`;

export const InfoText = styled(Typography)<{
  fontSize: string;
  label?: boolean;
}>`
  color: ${({ theme }) => theme.colors.black[500]};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: bold;
  flex: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;

  ${({ label }) =>
    label &&
    css`
      align-items: flex-end;
      align-content: center;
    `}

  span {
    flex-wrap: wrap;
    color: ${({ theme }) => theme.colors.grey[500]};
    line-height: 0.5rem;
    font-size: 0.75rem;
    font-weight: 400;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      width: 66%;
      justify-content: flex-start;
      align-items: center;
      flex: none;
      margin-left: 1.0625rem;
      font-size: 1rem;

      span {
        margin-left: 0.5rem;
      }
    }
  `}

  sup {
    margin-bottom: 0.5rem;
  }
`;

export const Hr = styled.hr`
  background-color: ${({ theme }) => theme.colors.grey[600]};
  height: 1px;
  width: 100%;
  display: inline-block;
  border: none;
  border-radius: 0.3rem;
  margin: 1rem 0;
  opacity: 0.6;

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      display: none;
    }
  `}
`;

export const Text = styled(Typography)<{ bold?: boolean }>`
  color: ${({ theme }) => theme.colors.black[500]};

  ${({ theme, bold }) =>
    bold
      ? css`
          font-weight: bold;
        `
      : css`
          margin-bottom: 2rem;
          ${theme.breakpoints.down('xs')} {
            margin-bottom: 1rem;
            margin-top: 1rem;
          }
        `}
`;

export const DescriptionContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      height: 100%;
    }
    ${theme.breakpoints.down('xs')} {
      flex-direction: column-reverse;
    }
  `}
`;

export const StyledLayoutScroll = styled(
  ({ children, trackStyles, thumbStyles, scrollbarStyles }) => (
    <LayoutScroll
      trackStyles={trackStyles}
      thumbStyles={thumbStyles}
      scrollbarStyles={scrollbarStyles}
    >
      {children}
    </LayoutScroll>
  )
).attrs(({ theme }) => ({
  trackStyles: css`
    height: 0.8125rem !important;
    width: 90% !important;
    border-radius: 0.5rem !important;
    margin-left: 3% !important;
    background-color: ${theme.colors.grey[400]} !important;
  `,
  thumbStyles: css`
    border-radius: 0.5rem !important;
    background-color: ${theme.colorMappings.primaryButtonBackground} !important;
  `,
  scrollbarStyles: css`
    ${theme.breakpoints.down('sm')} {
      height: 9rem !important;
    }
    ${theme.breakpoints.down('xs')} {
      height: 17rem !important;
    }
    & .ScrollbarsCustom-Content {
      margin: 0 !important;
    }
  `,
}))`
  display: none;
  ${({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      display: initial;
    }
  `}
`;
