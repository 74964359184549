import * as React from 'react';
import { CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Separator } from 'Atoms';
import { ProposalPageCf3 } from 'Client/pages/proposals/ProposalPageCf3';
import { SlidePanel } from '../MapSlidePanel';
import {
  LoaderContainer,
  PanelHeader,
  PanelIcon,
  PanelTitle,
} from './ContributionFlowPanelCf3.styles';

export const ContributionFlowPanelCf3 = (props): JSX.Element => {
  // const {
  //   dispatch,
  //   state: { proposal, contributionFlow, xyz, draftContributionLayer },
  // } = useMap();
  // const project = useProject();
  // const router = useRouter();
  // const { data: session } = useSession();
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false); // TODO: call setLoading(false) when all props really finished loading
    }, 5000);
  }, []);

  return (
    <SlidePanel onClose={() => {}}>
      <PanelHeader>
        <PanelIcon
          width="20"
          height="20"
          src="/static/android-chrome-filled-192x192.png"
        />
        <PanelTitle>{t('Have your say')}</PanelTitle>
        <Separator height={'0.125rem'} />
      </PanelHeader>
      {/* {isHandlingSubmit && <LoadingScreen withLoadingRing={true} />} */}
      {loading ? (
        <LoaderContainer>
          <CircularProgress />
        </LoaderContainer>
      ) : (
        <ProposalPageCf3 {...props} />
      )}
    </SlidePanel>
  );
};
