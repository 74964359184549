import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { useRouter } from 'next/router';
import { captureException } from '@sentry/node';
import { RootState } from 'Client/redux-store';
import {
  setMembersToAdd,
  setRemoveMemberModal,
} from 'Client/utils/reduxReducers/projectCentre/projectCenterReducer';
import { StyledCheckbox } from 'Atoms';
import {
  TeamMember,
  TeamMemberWithCurrentProject,
} from 'Shared/types/teamMembers';
import { DELETE_DESCOPE_USER } from 'Client/utils/gql/mutations.gql';
import {
  Modal,
  Wrapper,
  TextMain,
  Text,
  TextDiv,
  FormDivAdmin,
  FormDiv,
  Delete,
  SearchInput,
  SearchResultsDiv,
  UserRow,
  UserRollCell,
  ReplaceButton,
  ProjectsDiv,
  ProjectsRow,
  ProjectName,
} from './RemoveMemberModal.styles';
import {
  getProjectListAsOptions,
  handleSetProjects,
} from '../AddMembersModal/utils';
import { ButtonsDiv, CancelButton as Cancel } from '../SharedModal.styles';
import {
  getAdminCount,
  getMemberWithCurrentProject,
} from '../TeamProjectRow/utils/getTeamMembersFromProject';
import { REMOVE_MEMBER_MUTATION } from './RemoveMemberModal.gql';

export const RemoveMemberModal = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { t } = useTranslation('customer');
  const {
    removeMemberModal,
    customerProjectList,
    teamMembers,
    selectedCustomer,
  } = useSelector((state: RootState) => state.projectCentre);
  const [removeMember] = useMutation(REMOVE_MEMBER_MUTATION);

  const [member, setMember] =
    React.useState<TeamMemberWithCurrentProject>(undefined);
  const [adminCount, setAdminCount] = React.useState<number>(0);
  const [adminToReplace, setAdminToReplace] = React.useState({
    email: '',
    firstName: '',
    lastName: '',
  });
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [searchResult, setSearchResult] = React.useState<Array<TeamMember>>([]);
  const [selectOptions, setSelectOptions] = React.useState([]);
  const [selectedProjects, setSelectedProjects] = React.useState<Array<string>>(
    []
  );
  const [deleteDescopeUser] = useMutation(DELETE_DESCOPE_USER);

  const handleClose = () => {
    setSelectedProjects([]);
    setAdminToReplace({
      email: '',
      firstName: '',
      lastName: '',
    });
    dispatch(
      setRemoveMemberModal({ active: false, memberEmail: '', projectId: '' })
    );
  };

  const handleReplaceClick = (teamMember: TeamMember) => {
    setAdminToReplace((previous) => {
      if (previous.email !== teamMember.email) {
        return {
          email: teamMember.email,
          firstName: teamMember.firstName,
          lastName: teamMember.lastName,
        };
      }

      return {
        email: '',
        firstName: '',
        lastName: '',
      };
    });
  };

  const handleAdminReplace = async () => {
    //remove admin
    await removeMember({
      variables: {
        memberId: member._id,
        memberEmail: member.email,
        projects: [String(member.currentProject._id)],
        customerId: selectedCustomer.hubspot_id,
      },
    });

    //replace admin
    dispatch(
      setMembersToAdd({
        firstName: [adminToReplace.firstName],
        lastName: [adminToReplace.lastName],
        email: [adminToReplace.email],
        role: ['Admin'],
        selectedProjects: [String(member.currentProject._id)],
      })
    );

    handleClose();
  };

  const handleRemoveTeamMember = async () => {
    try {
      const projectsToBeRemoved = (() => {
        if (selectedProjects.includes('account level')) {
          return customerProjectList.map((p) => p._id);
        }

        return selectedProjects;
      })();

      const removeResponse = await removeMember({
        variables: {
          memberId: member._id,
          memberEmail: member.email,
          projects: projectsToBeRemoved,
          customerId: selectedCustomer.hubspot_id,
        },
      });

      const userEmail = member.email;

      await deleteDescopeUser({
        variables: {
          deleteDescopeUserInput: {
            userEmail,
          },
        },
      });

      if (removeResponse) {
        router.reload();
      }

      handleClose();
    } catch (error) {
      console.error('handleRemoveTeamMember() error: ', handleRemoveTeamMember);
      captureException(`handleRemoveTeamMember() error: ${error}`);
    }
  };

  React.useEffect(() => {
    const memberFound = teamMembers.find((m) => {
      return m.email === removeMemberModal.memberEmail;
    });
    if (!memberFound) {
      return;
    }
    const memberWithProjectInfo = getMemberWithCurrentProject(
      memberFound,
      removeMemberModal.projectId,
      undefined
    );
    const adminCount = getAdminCount(teamMembers, removeMemberModal.projectId);
    setAdminCount(adminCount);
    setMember(memberWithProjectInfo);
  }, [removeMemberModal]);

  React.useEffect(() => {
    if (searchValue) {
      const newResult = teamMembers
        .map((m) => {
          if (m.email === removeMemberModal.memberEmail) {
            return;
          }
          const string = `${m.firstName} ${m.lastName} ${m.email}`;
          if (string.toLowerCase().includes(searchValue.toLowerCase())) {
            return m;
          }
          return;
        })
        .filter(Boolean);

      setSearchResult(newResult);
      return;
    }

    setSearchResult([]);
    return () => {
      setSearchResult([]);
    };
  }, [searchValue]);

  React.useEffect(() => {
    if (!removeMemberModal.active) {
      setSearchValue('');
    }
  }, [removeMemberModal.active]);

  React.useEffect(() => {
    const selectOptions = getProjectListAsOptions({
      projects: customerProjectList,
    });

    setSelectOptions(selectOptions);
  }, [customerProjectList]);

  return (
    <Modal open={removeMemberModal.active} onClose={() => handleClose()}>
      {member &&
      ['Admin', 'admin'].includes(member.currentProject?.role) &&
      adminCount <= 1 ? (
        <Wrapper>
          <TextDiv>
            <img src="/static/illustrations/team_management_remove_member.png" />
            <TextMain>
              {member?.firstName} {member?.lastName} is an <b>Admin</b>
            </TextMain>
            <Text>{t('There must be at least one admin on each project')}</Text>
          </TextDiv>
          <FormDivAdmin>
            <Text>
              {t(
                'Please give admin access to someone else before removing this member.'
              )}
            </Text>
            <SearchInput
              type="text"
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder={t('Search team members...')}
            />
            <SearchResultsDiv>
              {searchResult.map((sr, index) => {
                return (
                  <UserRow key={index}>
                    <UserRollCell>
                      {sr.firstName} {sr.lastName}
                    </UserRollCell>
                    <UserRollCell>{sr.email}</UserRollCell>
                    <UserRollCell>{sr.projects[0]?.role || ''}</UserRollCell>
                    <UserRollCell>
                      <ReplaceButton
                        onClick={() => handleReplaceClick(sr)}
                        selected={adminToReplace.email === sr.email}
                      >
                        {adminToReplace.email === sr.email
                          ? t('Selected to replace')
                          : t('Replace admin')}
                      </ReplaceButton>
                    </UserRollCell>
                  </UserRow>
                );
              })}
            </SearchResultsDiv>
          </FormDivAdmin>
          <ButtonsDiv>
            <Cancel onClick={() => handleClose()}>{t('Cancel')}</Cancel>
            <Delete
              onClick={() => {
                if (adminToReplace.email !== '') {
                  handleAdminReplace();
                }
              }}
            >
              {t('Delete and replace admin')}
            </Delete>
          </ButtonsDiv>
        </Wrapper>
      ) : (
        <Wrapper>
          <TextDiv>
            <img src="/static/illustrations/team_management_remove_member.png" />
            <TextMain>
              {t('Do you want to remove')} <b>{member?.firstName}?</b>
            </TextMain>
            <Text>
              {t("This user won't have access to the following projects")}
            </Text>
          </TextDiv>
          <FormDiv>
            <ProjectsDiv>
              {selectOptions.map((p, index) => {
                return (
                  <ProjectsRow
                    key={index}
                    programmeStyle={['account level'].includes(p.value)}
                  >
                    <StyledCheckbox
                      type="checkbox"
                      checked={selectedProjects.includes(p.value)}
                      onChange={() => {
                        handleSetProjects({
                          value: p.value,
                          setSelectedProjects,
                          selectedProjects,
                          setProjectError: () => '',
                        });
                      }}
                    />
                    <ProjectName>{p.label}</ProjectName>
                  </ProjectsRow>
                );
              })}
            </ProjectsDiv>
          </FormDiv>
          <ButtonsDiv>
            <Cancel onClick={() => handleClose()}>{t('Cancel')}</Cancel>
            <Delete onClick={() => handleRemoveTeamMember()}>
              {t('Delete')}
            </Delete>
          </ButtonsDiv>
        </Wrapper>
      )}
    </Modal>
  );
};
