import { gql } from '@apollo/client';

export const REMOVE_MEMBER_MUTATION = gql`
  mutation Mutation(
    $memberId: String
    $memberEmail: String
    $projects: [String]
    $customerId: String
    $accountLevel: Boolean
  ) {
    removeMemberFromProject(
      memberId: $memberId
      memberEmail: $memberEmail
      projects: $projects
      customerId: $customerId
      accountLevel: $accountLevel
    ) {
      _id
      email
      firstName
      lastName
      projects {
        _id
        role
        scope
      }
      customers {
        _id
        role
        scope
      }
    }
  }
`;
