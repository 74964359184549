import * as React from 'react';
import styled, { css } from 'styled-components';
import { Typography } from 'Atoms';

export const Wrapper = styled.div`
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      flex-direction: column;
      padding: 1rem 0 0;
      position: relative;
      margin-top: 1.25rem;
    }
  `}
`;

export const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      width: 100%;
      height: 7.875rem;
      padding: 1rem;
      margin: 1rem;
      border-top: 1px solid #eaeaea;
      border-bottom: 1px solid #eaeaea;
      flex-direction: column;
      align-items: baseline;
      justify-content: space-around;
    }
  `}
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 60%;

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      max-width: 100%;
      align-items: center;
    }
  `}
`;

export const CommentsColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 45%;

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      max-width: 100%;
      align-items: center;
      flex-direction: row;
    }
  `}
`;

export const Title = styled(({ small, ...props }) => <Typography {...props} />)`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black[500]};
  line-height: 1.9rem;
  padding-bottom: 1rem;

  ${({ theme, small }) => css`
    font-size: ${small ? '1.4rem' : '1.6rem'};

    ${theme.breakpoints.down('xs')} {
      font-size: ${small ? '1.25rem' : '1.7rem'};
      padding-bottom: 0;
    }
  `}
`;

export const Description = styled(Typography)`
  color: ${({ theme }) => theme.colors.black[500]};

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      margin-right: 0.5rem;
      span {
        font-weight: bold;
      }
    }
  `}
`;

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;

  & p {
    margin-left: 0.5rem;
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      margin-left: 0.1875rem;

      & p {
        margin-left: 1.0625rem;
      }
    }
  `}
`;

export const CommentsWrapper = styled.div`
  display: flex;
  align-self: flex-end;

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      display: none;
    }
  `}
`;

export const CommentsWrapperMobile = styled.div`
  display: none;

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      display: initial;

      div > svg {
        font-size: 1.1875rem !important;
        margin-right: 0.1875rem !important;
      }
    }
  `}
`;

export const TitleWrapper = styled.div`
  display: flex;

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      width: 100%;
      justify-content: flex-start;
      padding: 0 1rem;
    }
  `}
`;

export const BackButtonWrapper = styled.div`
  display: none;
  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      display: flex;
      margin-right: 1.25rem;
      & button > span > svg {
        padding-bottom: 0.3125rem;
      }
    }
  `}
`;
