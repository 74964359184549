import { gql } from '@apollo/client';

export const GET_CONTRIBUTIONS_QUERY = gql`
  query Query(
    $getTotalContributionsForProjectInput: GetTotalDashboardCommentsForProjectInput!
    $getTotalCommentsForProjectInput: GetTotalDashboardCommentsForProjectInput!
    $getTotalAgreementsForProjectInput: GetTotalDashboardCommentsForProjectInput!
    $getTotalDashboardCommentsForProjectInput: GetTotalDashboardCommentsForProjectInput!
    $getDashboardCommentsForProjectInput: GetDashboardCommentsForProjectInput!
    $getDashboardMapCommentsForProjectInput: GetDashboardCommentsForProjectInput!
    $getPopulatedQuestionsForProjectInput: getPopulatedQuestionsForProjectInput!
    $getContributionsTableColumnsInput: GetContributionsTableColumnsInput!
  ) {
    getTotalContributionsForProject(
      getTotalContributionsForProjectInput: $getTotalContributionsForProjectInput
    )
    getTotalCommentsForProject(
      getTotalCommentsForProjectInput: $getTotalCommentsForProjectInput
    )
    getTotalAgreementsForProject(
      getTotalAgreementsForProjectInput: $getTotalAgreementsForProjectInput
    )
    getTotalDashboardCommentsForProject(
      getTotalDashboardCommentsForProjectInput: $getTotalDashboardCommentsForProjectInput
    )
    getDashboardCommentsForProject(
      getDashboardCommentsForProjectInput: $getDashboardCommentsForProjectInput
    )
    getDashboardMapCommentsForProject(
      getDashboardMapCommentsForProjectInput: $getDashboardMapCommentsForProjectInput
    )
    getPopulatedQuestionsForProject(
      getPopulatedQuestionsForProjectInput: $getPopulatedQuestionsForProjectInput
    )
    getContributionsTableColumnsV2(
      getContributionsTableColumnsInput: $getContributionsTableColumnsInput
    ) {
      Header
      id
      columns {
        Header
        accessor
        questionContent
      }
    }
  }
`;

export const GET_PAGES_DROPDOWN = gql`
  query Query($getDashboardPagesDropDownInput: String!) {
    getDashboardPagesDropDown(
      getDashboardPagesDropDownInput: $getDashboardPagesDropDownInput
    ) {
      contributionsCount
      slug
      stage
      title
    }
  }
`;

export const FILTER_QUERY = gql`
  query Query(
    $getCommentsAnswersForProjectInput: String!
    $getProjectSettingsInput: GetProjectSettingsInput
    $getContributionsFilterOptionsInput: GetContributionsFilterOptionsInput!
  ) {
    getCommentsAnswersForProject(
      getCommentsAnswersForProjectInput: $getCommentsAnswersForProjectInput
    )
    getProjectSettings(GetProjectSettingsInput: $getProjectSettingsInput) {
      locales
    }
    getContributionsFilterOptions(
      getContributionsFilterOptionsInput: $getContributionsFilterOptionsInput
    ) {
      conditions
      property
      questionContent
      rules
      isHighlight
    }
  }
`;

export const EXPORT_MUTATION = gql`
  mutation Mutation($dashboardCsvExportInput: DashboardCSVExportInput) {
    handleDashboardCSVExport(
      dashboardCSVExportInput: $dashboardCsvExportInput
    ) {
      message
      status
    }
  }
`;
