import { t } from 'i18next';
import { isFuture } from 'date-fns';
import { ProjectGaudi } from 'Shared/types';
import { PlanningAppContent } from 'Shared/types/planningapp';
import { TimelineMilestone } from '../components/TimeLine/types';

type Props = {
  pageContent: PlanningAppContent;
  project: ProjectGaudi;
};

export const getTimelineMilestones = ({
  pageContent,
}: Props): TimelineMilestone[] => {
  if (!pageContent?.stage) {
    return [
      pageContent.actualCommittee
        ? null
        : {
            date: pageContent.receivedDate,
            description: t('Date received'),
          },
      {
        date: pageContent.validatedDate,
        description: t('Validated • Consultation start'),
      },
      {
        date: pageContent.consultationEnd,
        description: t('Consultation end'),
      },
      pageContent.actualCommittee
        ? {
            date: pageContent.actualCommittee,
            description: t('Committee date'),
          }
        : null,
      {
        date: pageContent.decisionDate || pageContent.targetDate,
        description: pageContent.decisionDate
          ? t('Decision date')
          : t('Expected decision'),
      },
    ].filter(Boolean);
  }

  const stages = {
    'Stage 1': [
      { date: pageContent.receivedDate, description: t('Date received') },
      {
        date: pageContent.validatedDate,
        description: t('Validated • Comments open'),
      },
      {
        date: pageContent.latestDecisionDate,
        description: t('Expected stage 1 response'),
      },
      {
        date: pageContent.decisionDate,
        description: t('Stage 1 issued  • Stage 2 TBC'),
      },
    ],
    'Stage 2': [
      {
        date: pageContent.validatedDate,
        description: t('Stage 2 received'),
      },
      {
        date: pageContent.consultationEnd,
        description: t('Comments closed'),
      },
      {
        date: pageContent.latestDecisionDate,
        description: t('Expected decision'),
      },
      {
        date: pageContent.mayorMeetingDate,
        description: t('Decision date'),
      },
    ],
    'Stage 3': [
      {
        date: pageContent.validatedDate,
        description: t('Validated • Comments open'),
      },
      {
        date: pageContent.consultationEnd,
        description: t('Consultation end'),
      },
      {
        date: pageContent.actualCommittee,
        description: t('Public hearing date'),
      },
      {
        date: null,
        description: t('Decision issued'),
      },
    ],
    'No Strategic Issues': [
      {
        date: pageContent.receivedDate,
        description: t('Date received'),
      },
      {
        date: pageContent.validatedDate,
        description: t('Validated • Comments open'),
      },
      {
        date: pageContent.decisionDate,
        description: t('Decision issued'),
      },
    ],
    'Stopping up order': [
      {
        date: pageContent.validatedDate,
        description: t('Validated • Comments open'),
      },
      {
        date: pageContent.targetDate,
        description: isFuture(pageContent.latestDecisionDate)
          ? t('Decision date')
          : t('Expected decision'),
      },
      {
        date: pageContent.decisionDate,
        description: t('Decision issued'),
      },
    ],
  };

  return stages[pageContent.stage] || [];
};
